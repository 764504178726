html {
    font-size: 62.5%;
    /* font-family: 'Inter var', Roboto, Helvetica Neue, Arial, "Kanit", sans-serif; */
    font-family: Kanit, sans-serif !important;
    background-color: #f1f5f9;
}

.kanit-thin {
    font-family: "Kanit", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.kanit-extralight {
    font-family: "Kanit", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.kanit-light {
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.kanit-regular {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.kanit-medium {
    font-family: "Kanit", sans-serif !important;
    font-weight: 500;
    font-style: normal;
}

.kanit-semibold {
    font-family: "Kanit", sans-serif !important;
    font-weight: 600;
    font-style: normal;
}

.kanit-bold {
    font-family: "Kanit", sans-serif !important;
    font-weight: 700;
    font-style: normal;
}

.kanit-extrabold {
    font-family: "Kanit", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.kanit-black {
    font-family: "Kanit", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.kanit-thin-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.kanit-extralight-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.kanit-light-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.kanit-regular-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.kanit-medium-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.kanit-semibold-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.kanit-bold-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.kanit-extrabold-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.kanit-black-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 900;
    font-style: italic;
}

.swal2-popup {
    font-size: 1.5rem !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
    background-color: #0175EA !important;
}

.swal2-popup .swal2-styled:focus {
    background-color: #00a6ff !important;
}

.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
    background-color: #00a6ff !important;
}

.swal2-container {
    z-index: 1060999 !important
}

.w-full {
    width: 100%;
}


.p-32 {
    padding: 3.2rem
}

.p-12 {
    padding: 1.2rem;
}



.mt-16 {
    margin-top: 1.6rem !important;
}

.mt-32{
    margin-top: 3.2rem !important;
}

.pt-32 {
    padding-top: 3.2rem;
}

.pt-64 {
    padding-top: 6.4rem;
}

.pt-16 {
    padding-top: 1.6rem;
}

.mt-8 {
    margin-top: 0.8rem;
}

.mb-16 {
    margin-bottom: 1.6rem;
}

.text-align-right {
    text-align: right;
}

.h-60 {
    height: 60vh;
}

.h-70 {
    height: 70vh;
}

.content-center {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.w-800 {
    width: 800px;
}

.shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.rounded-2xl {
    border-radius: 1.6rem !important;
}

.bg {
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 100%;
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    height: auto;
}

.header {
    background-color: #111827;
}

.font-title {
    color: #05A2F3;
    font-size: 18px !important;
}

.font-title1 {
    color: #000000;
    font-size: 18px !important;
}

.tx-left {
    text-align: left;
}

.font-blue {
    color: #05A2F3;
}

.hidden {
    visibility: hidden;
}

.bt-submit {
    background-color: #05A2F3 !important;
    border-radius: 20px !important;
    color: #FFFFFF;
    width: 100px;
    font-size: 12px !important;
    font-family: "Kanit", sans-serif !important;
    font-weight: 100;
}


.bt-danger {
    background-color: #EC221F !important;
    border-radius: 20px !important;
    color: #FFFFFF;
    width: 100px;
    font-size: 12px !important;
    font-family: "Kanit", sans-serif !important;
    font-weight: 100;
}

.bt-upload {
    background-color: #05A2F3 !important;
    border-radius: 8px !important;
    color: #FFFFFF;
    width: 100px;
    font-size: 12px !important;
    font-family: "Kanit", sans-serif !important;
    font-weight: 100;
}

.bt-submit:hover {
    background-color: #0175EA !important;
}



.bt-back {
    border: 1 solid #05A2F3 !important;
    border-radius: 20px !important;
    color: #05A2F3;
    /* background-color: #ffffff !important; */
    width: 100px;
    font-size: 12px !important;
    font-family: "Kanit", sans-serif !important;
}

.bt-back:hover {
    border: 1 solid #0175EA !important;
}




.flex {
    display: flex;
}

.container {
    max-width: unset !important;
}

.border-box-img {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    position: relative;
}

.p-8 {
    padding: 8px;
}


.p-16 {
    padding: 16px;
}

.plr-16 {
    padding-left: 16px;
    padding-right: 16px;
}


.plr-32 {
    padding-left: 32px;
    padding-right: 32px;
}

.pbt-8 {
    padding-bottom: 8px;
    padding-top: 8px;
}

.display-center {
    display: flex;
    justify-content: center;

}

.pb-10 {
    padding-bottom: 10px;
}

.w-260 {
    width: 26rem;
}

.h-285 {
    height: 28.5rem;
}

.w-150 {
    width: 15rem;
}

.h-150 {
    height: 15rem;
}


.h-full {
    height: 100%;
}


.w-200 {
    width: 20rem;
}

.h-200 {
    height: 20rem;
}

.w-240 {
    width: 24rem;
}

.h-240 {
    height: 24rem;
}

.h-170 {
    height: 17rem;
}

.w-170 {
    width: 17rem;
}


.text-position {
    position: absolute;
    top: -10px;
    left: 10px;
    width: 70px;
    text-align: center;
    background-color: white;
    z-index: 1;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    white-space: nowrap;
}






.flex-auto {
    flex: 1 1 auto;
}

.items-center {
    align-items: center;
}

.relative {
    position: relative;
}

.justify-center {
    justify-content: center;
}

.bg-black {
    background-color: rgb(34 41 47 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
    --tw-bg-opacity: 0.5;
}

.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.inset-0 {
    inset: 0px;
}

.absolute {
    position: absolute;
}

.object-cover {
    object-fit: cover !important;
}

.text-64 {
    font-size: 64px;
}

.font-bold {
    font-weight: 500;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.text-pdpa {
    color: #000000;
    font-size: 14px !important;
}

.font-14 {
    font-size: 14px !important;
}

.br-1 {
    border-top: 1px solid #F0F0F0;

}

.h-65 {
    height: 65vh;
}

.bt-add {
    background-color: #15B79E !important;
    border-radius: 20px !important;
    color: #FFFFFF;
    width: 120px;
    font-size: 12px !important;
    font-family: "Kanit", sans-serif !important;
    font-weight: 100;

}



.bt-add:hover {
    background-color: #0e8b79 !important;
}

.white-space {
    white-space: nowrap;
}

.change-language {
    /* width: 477px; */
    /* height: 15px; */
    box-shadow: none;
    background: transparent;
    /* --border-radius: 47.5px; */
    /* color: #000000; */
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: none;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
  }